import React from "react";
import "./App.css";
//Router
import Routes from "./routes/Routes";
import CssBaseline from "@material-ui/core/CssBaseline";
import { BrowserRouter as Router } from "react-router-dom";
import HeaderContainer from "./components/header/HeaderContainer.js";
import Footer from './components/footer';

function App() {
  return (
    <Router>
      <div className="App">
        <CssBaseline />
        <HeaderContainer />
        <Routes />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
