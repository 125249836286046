import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    background: "linear-gradient(45deg, #001A70 30%, #000044 90%)",
    border: 0,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    height: 70,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

const FooterTop = () => {
  const classes = useStyles();
  return <Box className={classes.root}>Loquesea</Box>;
};

export default FooterTop;
