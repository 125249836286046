import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
//Redux
import { store } from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
//GraphQL
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { gql } from "@apollo/client";

const client = new ApolloClient({
  uri: "https://back-laravel-test.desarrollobirdcom.es/graphql",
  cache: new InMemoryCache(),
  headers: {
    Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiZTM2NzRlNzk3MWJlYTRmNTgzZGUzYjRiYzc2MTRmNDYxMmM1MTVkYmVmZmU0YmJiYjRiNmI5ZTlhZDU5ZTFlMWM4YjQ3YzkzNjY2YTJiOTMiLCJpYXQiOjE2MjY5MzQwMzIuODI3NDA3LCJuYmYiOjE2MjY5MzQwMzIuODI3NDExLCJleHAiOjE2NTg0NzAwMzIuODEwNDgsInN1YiI6IjIiLCJzY29wZXMiOltdfQ.AWdhIiMNKTQJKa_3vUZhQQKNkw96pjNWLrJu9y2s1D7YJeiw4Anz6X8OZ3uyGtSsE5g3h2rm71cC6qpqtA8v2TmJxrQ3qDca_8ktqXvwXMkuRSRcB9sWy40pQswBbeUgy2jy_pWfwYuUpBK1f8l_Kkvf3RTl88GGuXGQU8nhZqmcWKiasPrCXRhg7m0Ju9sxxqSVpZmn3orXWVDzakD9fZLB3hkZEiqgatEmp4_BlFKIIRF6BeVl-tIEZ2at8cs_qdeoljqHncIM6Qsaf0gEuDpjInQNbdZ2dovRNQoug5szyO24NULcQ3LsON1s2tqt3wartfAuMeFn-avEz-e7V3po_0kfJcHUzWaiQtUVzAGbC86L1ZZoSHQSc2OJxrYfQ12hUioHu3TOlwLaTM2a0-UIRtaauYG2z3U1jYDSWrJl2Sj_P43VXTh5iXxn-Oam9I_d33my4_svE3_4-qOF8ugVlBShkYJ6YhHJN-TUj0ZBn4Tmn_YcE5L4qHPSp15m0wNq6qX-X21LOWp8A8vYD_DKFrscNP3h9f-yx0HBt0SlrUeJ1bxL7Fw-JwWPwFf6oeml_AAunt5iHq5w1gUsLbVuNn4jLcE4q5QCzzXepbIY7ewOrRU0OorXjGY-KLm7EZdhrIAEMEFGaoFlAULH_je6yfyNMZfALia50WEaW1M`,
  }
});

client
  .query({
    query: gql`
     query users {
        users(first: 5){
          data {
            id
            email
            name
          }
        }
      }
    `,
  })
  .then((result) => console.log(result));

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
