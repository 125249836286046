import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "../components/sections/Home";
import Users from "../components/sections/Users";
import About from "../components/sections/About";

import Box from '@material-ui/core/Box';

const Routes = () => {
  return (
    <Box mt={8} py={4} bgcolor="background.paper">
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/users" component={Users} />
        <Route path="/about" component={About} />
      </Switch>
    </Box>
  );
};

export default Routes;
