import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";

const useStyles = makeStyles({
  root: {
    background: "linear-gradient(45deg, #000044 30%, #000 90%)",
    border: 0,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  iconFirst: {
      height: 16,
      marginRight: 4,
  },
  iconLasts: {
    height: 16,
    marginRight: 4,
    marginLeft: 16,
}
});

const FooterBootom = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
        <LocationOnIcon className={classes.iconFirst} />
        Polígono Centrovía, Calle Los Ángeles, 19 CP 50198 La Muela, Zaragoza
        <PhoneIcon className={classes.iconLasts} />
        976 14 92 60
        <EmailIcon className={classes.iconLasts} />
        info@oroel.com
      
    </Box>
  );
};

export default FooterBootom;
