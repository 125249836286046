import React from "react";
import FooterTop from "./FooterTop.js";
import FooterBotom from "./FooterBottom.js";

const Footer = () => {
  return (
    <>
      <FooterTop />
      <FooterBotom />
    </>
  );
};

export default Footer;
