import React from 'react';

const Users = () => {
    return ( <>
    <h1>Users</h1> 
    <h1>Users</h1> 
    <h1>Users</h1> 
    <h1>Users</h1> 
    <h1>Users</h1> 
    <h1>Users</h1> 
    <h1>Users</h1> 
    <h1>Users</h1> 
    <h1>Users</h1> 
    <h1>Users</h1> 
    </>);
}
 
export default Users;